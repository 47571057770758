import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, Curso } from "../models";
//import { ServicioProfesionalListado } from "./ServiciosListadoModule";

@Module({ namespaced: true, name: "CursosModule" })
export default class CursosModule extends VuexModule {
  errors: BackendError | null = null;
  cursos: Curso[] = [];
  curso: Curso | null = null;
  isLoading = false;

  @Action
  fetchById({ formularioId }: { formularioId: string }) {
    this.setLoad(true);
    const url = `/cursos-abiertos/${formularioId}`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setDetailData(data);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetch() {
    this.setLoad(true);
    const url = `/cursos-abiertos`;
    const query = ApiService.get(url);
    return query
      .then(({ data }) => {
        const now = new Date().getTime();

        // Dividir en dos arrays
        const futureCourses = data.filter(
          (course) => new Date(course.inicio).getTime() >= now
        );
        const pastCourses = data.filter(
          (course) => new Date(course.inicio).getTime() < now
        );

        // Ordenar cursos futuros de más cercana a más lejana
        futureCourses.sort((a, b) => {
          const dateA = new Date(a.inicio).getTime();
          const dateB = new Date(b.inicio).getTime();
          return dateA - dateB;
        });

        // Ordenar cursos pasados de más reciente a más antigua
        pastCourses.sort((a, b) => {
          const dateA = new Date(a.inicio).getTime();
          const dateB = new Date(b.inicio).getTime();
          return dateB - dateA;
        });

        // Concatenar los arrays
        const sortedData = futureCourses.concat(pastCourses);

        this.setData(sortedData);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  update({
    comarcaId,
    id,
    servicio,
  }: {
    comarcaId: string;
    id: string;
    servicio;
  }) {
    const url =
      comarcaId == null
        ? `/servicios-programados/${id}`
        : `/comarcas/${comarcaId}/servicios-programados/${id}`;

    return ApiService.put(url, servicio).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.cursos = data;
  }

  @Mutation
  setDetailData(data) {
    this.curso = data;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }

  /*@Mutation
  setProfesional(data) {
    this.profesionales = data;
  }*/
}
